<template>
	<v-sheet class="member" id="member" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="6" class="my-auto">
				<h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1>
			</v-col>
			<v-col md="6" class="text-right">
				<v-btn :disabled="pageLoading || profileUploading" depressed tile v-on:click="goBack()">
					Cancel
				</v-btn>
				<v-btn
					:loading="pageLoading"
					class="white--text ml-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading || profileUploading || !formValid"
					v-on:click="updateOrCreate()"
				>
					Save
				</v-btn>
			</v-col>
			<v-col md="12">
				<v-form
					ref="dbMemberForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="updateOrCreate"
				>
					<perfect-scrollbar
						:options="{ suppressScrollX: true }"
						class="scroll custom-box-top-inner-shadow"
						style="max-height: calc(100vh - 225px); position: relative"
					>
						<v-row class="px-4">
							<v-col md="8">
								<v-row>
									<v-col md="4" class="my-auto py-0">
										<label for="first-name" class="btx-label mt-2 required">Name</label>
									</v-col>
									<v-col md="8" class="py-0">
										<v-layout>
											<v-flex class="max-width-100px">
												<SelectInput
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													:items="listTitle"
													id="title"
													placeholder="Title"
													v-model="member.title"
												></SelectInput>
											</v-flex>
											<v-flex class="mx-2">
												<TextInput
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													id="first-name"
													placeholder="First Name"
													:rules="[vrules.required(member.first_name, 'First Name')]"
													:class="{
														required: !member.first_name,
													}"
													v-on:keyup="updateDisplayName()"
													v-model="member.first_name"
												></TextInput>
											</v-flex>
											<v-flex>
												<TextInput
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													id="last-name"
													placeholder="Last Name"
													v-on:keyup="updateDisplayName()"
													v-model="member.last_name"
												></TextInput>
											</v-flex>
										</v-layout>
									</v-col>
									<v-col md="4" class="my-auto py-0">
										<label for="display-name" class="btx-label mt-2 required"> Display Name </label>
									</v-col>
									<v-col md="8" class="py-0">
										<TextValidateInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="display-name"
											placeholder="Display Name"
											:rules="[vrules.required(member.display_name, 'Display Name')]"
											:class="{
												required: !member.display_name,
											}"
											:validationField="{
												url_type: 'member',
												filter_type: 'member',
												field: 'display_name',
											}"
											show-dropdown
											:parent-id="member.id"
											:current-id="member.id"
											v-model="member.display_name"
										></TextValidateInput>
									</v-col>
									<v-col md="4" class="my-auto py-0">
										<label for="id-number" class="btx-label mt-2 required"
											>User Name
											<TooltipQuestion v-if="false">
												<template v-slot:text
													>Your unique identifier for this<br />User. It can also be printed<br />and scanned as
													a barcode.</template
												>
											</TooltipQuestion>
										</label>
									</v-col>
									<v-col md="8" class="py-0">
										<TextValidateInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="user-name"
											placeholder="User Name"
											:rules="[vrules.required(member.user_name, 'user Name')]"
											:class="{
												required: !member.user_name,
											}"
											:validationField="{
												url_type: 'member',
												filter_type: 'member',
												field: 'user_name',
											}"
											:parent-id="member.id"
											:current-id="member.id"
											v-model="member.user_name"
										></TextValidateInput>
									</v-col>
									<v-col md="4" class="my-auto py-0">
										<label for="member-email" class="btx-label mt-2 required">Email</label>
									</v-col>
									<v-col md="8" class="py-0">
										<EmailInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="member-email"
											placeholder="Email"
											v-model="member.email"
											:rules="[vrules.required(member.email, 'Email')]"
											:class="{
												required: !member.email,
											}"
											:validation-field="{
												url_type: 'member',
												filter_type: 'member',
												field: 'email',
											}"
											:parent-id="member.id"
											:current-id="member.id"
										></EmailInput>
									</v-col>
									<v-col md="4" class="my-auto py-0">
										<label for="phone-number" class="btx-label mt-2 required">Phone Number</label>
									</v-col>
									<v-col md="8" class="py-0">
										<PhoneInput
											required
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="phone-number"
											placeholder="Phone No."
											v-model="member.phone_number"
											:validation-field="{
												url_type: 'member',
												filter_type: 'member',
												field: 'phone_number',
											}"
											:parent-id="member.id"
											:current-id="member.id"
										></PhoneInput>
									</v-col>
									<v-col md="4" class="my-auto py-0">
										<label for="role" class="btx-label mt-2 required">Role</label>
									</v-col>
									<v-col md="8" class="py-0">
										<SelectInput
											hide-details
											:items="roleList"
											:disabled="pageLoading"
											:loading="pageLoading"
											id="role"
											:rules="[vrules.required(member.role, 'Role')]"
											:class="{
												required: !member.role,
											}"
											placeholder="Role"
											v-model="member.role"
										></SelectInput>
									</v-col>
									<v-col md="4" class="my-auto py-0">
										<label for="security-code" class="btx-label mt-2 required">Security Code</label>
									</v-col>
									<v-col md="8" class="py-0">
										<TextValidateInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="security-code"
											placeholder="Security Code"
											:rules="[
												vrules.required(member.security_code, 'security code'),
												vrules.minLength(member.security_code, 'security code', 4),
												vrules.maxLength(member.security_code, 'security code', 10),
											]"
											:class="{
												required: !member.security_code,
											}"
											:validationField="{
												url_type: 'member',
												filter_type: 'member',
												field: 'security_code',
											}"
											:parent-id="member.id"
											:current-id="member.id"
											v-model="member.security_code"
										></TextValidateInput>
										<!-- <TextInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="security-code"
											placeholder="Security Code"
											:rules="[
												vrules.required(member.security_code, 'security code'),
												vrules.minLength(member.security_code, 'security code', 4),
												vrules.maxLength(member.security_code, 'security code', 10),
												securityCodeError ? false : true,
											]"
											:class="{
												required: !member.security_code,
											}"
											v-model="member.security_code"
											v-on:blur="validateSecurityCode($event)"
										></TextInput>
										<span v-if="securityCodeError" class="red--text text--darken-1 font-small">{{
											securityCodeError
										}}</span> -->
									</v-col>
									<v-col md="4" class="my-auto py-0" v-if="!uuid">
										<label for="password" class="btx-label mt-2 required">Password</label>
									</v-col>
									<v-col md="8" class="py-0" v-if="!uuid">
										<TextInput
											hide-details
											:disabled="pageLoading"
											:type="show1 ? 'text' : 'password'"
											:loading="pageLoading"
											:append-outer-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
											v-on:click:append-outer="show1 = !show1"
											id="password"
											placeholder="Password"
											:rules="[
												vrules.required(member.password, 'New Password'),
												vrules.minLength(member.password, 'New Password', 8),
												vrules.maxLength(member.password, 'New Password', 16),
											]"
											:class="{
												required: !member.password,
											}"
											v-model="member.password"
										></TextInput>
									</v-col>
								</v-row>
							</v-col>
							<v-col md="4">
								<v-row>
									<v-col md="12" class="my-6 py-0 text-center">
										<Avatar
											@loading="($event) => (profileUploading = $event)"
											can-change
											v-model="member.image"
										></Avatar>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</perfect-scrollbar>
				</v-form>
			</v-col>
		</v-row>
	</v-sheet>
</template>
<script>
import { toString } from "lodash";
import { mapGetters } from "vuex";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import {
	CreateMember,
	UpdateMember,
	GetMember,
	GetSecurityCode,
	ValidateSecurityCode,
} from "@/core/lib/member.lib";
import TextInput from "@/view/components/TextInput";
import EmailInput from "@/view/components/EmailInput";
import PhoneInput from "@/view/components/PhoneInput";
import SelectInput from "@/view/components/SelectInput";
import TextValidateInput from "@/view/components/TextValidateInput";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import Avatar from "@/view/components/Avatar";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";

export default {
	name: "member-create",
	title: "Create Member",
	data() {
		return {
			uuid: null,
			stepper: 1,
			show1: false,
			barcode: null,
			formValid: true,
			pageLoading: false,
			profileUploading: false,
			securityCodeLoading: false,
			securityCodeError: null,
			roleList: [],
			address: {},
			member: {
				id: null,
				uuid: null,
				title: "mr",
				first_name: null,
				last_name: null,
				email: null,
				display_name: null,
				user_name: null,
				department: null,
				image: null,
				description: null,
				phone_number: null,
				security_code: null,
				role: 0,
			},
		};
	},
	components: {
		TextInput,
		EmailInput,
		PhoneInput,
		TextValidateInput,
		TooltipQuestion,
		Avatar,
		SelectInput,
	},
	methods: {
		updateDisplayName() {
			this.member.display_name = `${toString(this.member.first_name)} ${toString(
				this.member.last_name
			)}`;
		},
		async updateOrCreate() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.dbMemberForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.dbMemberForm.validate()) {
				return false;
			}
			const formData = {
				id: _this.member.id,
				uuid: _this.member.uuid,
				title: _this.member.title,
				first_name: _this.member.first_name,
				last_name: _this.member.last_name,
				email: _this.member.email,
				display_name: _this.member.display_name,
				user_name: _this.member.user_name,
				department: _this.member.department,
				image: _this.member.image,
				description: _this.member.description,
				phone_number: _this.member.phone_number,
				password: _this.member.password,
				security_code: _this.member.security_code,
				role: _this.member.role,
			};

			try {
				_this.pageLoading = true;

				if (_this.uuid) {
					const { uuid } = await UpdateMember(_this.uuid, formData);
					_this.$router.replace({
						name: "user-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! User has been updated." },
					]);
					_this.$store.dispatch(SET_LOCAL_DB);
				} else {
					const { uuid } = await CreateMember(formData);
					_this.$router.replace({
						name: "user-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! User has been created." },
					]);
					_this.$store.dispatch(SET_LOCAL_DB);
				}
			} catch (error) {
				_this.logError(error);
				// _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		getMember() {
			GetMember(this.uuid)
				.then((data) => {
					this.member.id = data.id;
					this.member.uuid = data.uuid;
					this.member.title = data.title;
					this.member.first_name = data.first_name;
					this.member.last_name = data.last_name;
					this.member.email = data.email;
					this.member.display_name = data.display_name;
					this.member.user_name = data.user_name;
					this.member.department = data.department;
					this.member.image = data.image;
					this.member.description = data.description;
					this.member.phone_number = data.phone_number;
					this.member.security_code = data.security_code;
					this.member.role = data.role ? data.role.id : null;
					this.barcode = data.barcode;
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "User", disabled: true },
						{ text: "Update", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.logError(error);
					// this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		async getSecurityCode() {
			this.securityCodeLoading = true;
			try {
				const { security_code } = await GetSecurityCode();
				this.member.security_code = security_code;
			} catch (error) {
				console.log(error);
			} finally {
				this.securityCodeLoading = false;
			}
		},
		async validateSecurityCode(code) {
			try {
				this.securityCodeError = null;
				const { exists } = await ValidateSecurityCode(code);
				if (exists) {
					this.securityCodeError = `${code} already exists`;
				}
			} catch (error) {
				console.log(error);
			}
		},
		pageTitle() {
			if (this.uuid) {
				return "Update User - " + this.member.display_name;
			}
			return "Create new User";
		},
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
	},
	mounted() {
		this.roleList = this.localDB("roles", []);

		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "User", disabled: true },
			{ text: "Create", disabled: true },
		]);

		const { name, params } = this.$route;
		if (name === "user-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.pageLoading = true;
			this.getMember();
		} else {
			this.getSecurityCode();
		}
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
